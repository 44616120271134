<template>
    <section id="goose-grid-section" class="d-flex flex-wrap flex-shrink-0 mb-5">
        <img :key="'A'+ index" v-for="(player, index) in players" class="icon"
            :id="player.id" :src="player.src" :alt="player.alt" :class="(player.stop) ? 'bg-danger' : ''"
            :style="{ left: ('calc((75vh / 9) * ' + player.colCounter), top: ('calc((75vh / 9) * ' + player.rowCounter) }">
        <div class="box d-flex justify-content-center align-items-center" :key="index" v-for="(box,index) in boxes"
            :class="`box-${box.boxType}`">
            <h1 class="m-0">{{box.boxID}}</h1>  
        </div>
        <div v-if="winner.name!=undefined" class="winner-container rounded d-flex flex-column align-items-center justify-content-around py-5">
            <img :src="winner.src" :alt="winner.name" class="winner-img">
            <div class="text-center">
                <h3 class="p-3">{{winner.name}} ha catturato il CyberCriminale!</h3> 
                <button class="btn btn-lg mt-3" @click="reloadPage">Play Again</button>
            </div>
        </div>
    </section>
</template>

<script>

    export default {
        name : 'GooseGrid',
        data: function () {
            return {
                boxes : [],
                players : [
                    {
                        id : 'Cyberino',
                        src : require('./../../assets/Cyberino-nowriting.png'),
                        winSrc : require('./../../assets/Cyberino_vittoria.png'),
                        alt : 'cyberino logo',
                        colCounter : 0,
                        rowCounter : 0,
                        stop: false
                    },
                    {
                        id : 'Super-Byte',
                        src : require('./../../assets/SuperByte-nowriting.png'),
                        winSrc : require('./../../assets/SuperByte_vittoria.png'),
                        alt : 'super bite logo',
                        colCounter : 0,
                        rowCounter : 0,
                        stop: false
                    },
                    {
                        id : 'Securya',
                        src : require('./../../assets/Securya-nowriting.png'),
                        winSrc : require('./../../assets/Securya_vittoria.png'),
                        alt : 'securya logo',
                        colCounter : 0,
                        rowCounter : 0,
                        stop: false
                    },
                    {
                        id : 'Digitalia',
                        src : require('./../../assets/Digitalia-nowriting.png'),
                        winSrc : require('./../../assets/Digitalia_vittoria.png'),
                        alt : 'digitalia logo',
                        colCounter : 0,
                        rowCounter : 0,
                        stop: false
                    }
                ],
                winner : {}
            }
        },
        created() {
            this.$root.$refs.GooseGrid = this;
        },
        methods : {
            movePlayer(index){
                this.players[index].colCounter++
                if(this.players[index].colCounter % 9 === 0){
                    this.players[index].colCounter = 0
                    this.players[index].rowCounter++
                }
                if(this.players[index].rowCounter > 8){
                    this.winner.name = this.players[index].id
                    this.winner.src = this.players[index].winSrc
                    this.players.forEach(element => {
                        element.colCounter = 0
                        element.rowCounter = 0
                    });
                }
            },
            movePlayerBack(index){
                this.players[index].colCounter--
                if(this.players[index].colCounter < 0){
                    this.players[index].colCounter = 8
                    this.players[index].rowCounter--
                }
                
            },
            reloadPage(){
                location.reload()
            }

        },
        mounted(){
            for (let i = 0; i < 81; i++) {
                let type = ''
                if(i === 6 || i === 14 || i === 18 || i === 24 ||i === 32 || i === 37 ||i === 42 ||i === 45 ||i === 59 || i === 64 || i === 75 || i === 79)
                    type = 'stop'
                else if(i === 10 || i === 15 || i === 19 || i === 25 || i === 39 || i === 49 || i === 56 || i === 50 || i === 65)
                    type = 'back'
                else
                    type = 'default'
                this.boxes.push({
                    boxID : i,
                    boxType : type
                })
            }
        },
        updated(){

        }
    }
</script>

 <style scoped lang="sass">

    $box-1x1 : calc(75vh / 9)
    #goose-grid-section
        width: $box-1x1 * 9 //80px width of single box, 0 sqrt of 81 (for index)
        position: relative
    .box
        height: $box-1x1
        width: $box-1x1
        border: solid 1px #036507
        h1
            font-size: $box-1x1 - 10vh
            font-weight: bold
            color: rgba(255, 255, 255, 0.8)
    .box-default
        background-color: rgba(3, 101, 7, 0.6)
    .box-stop
        background-color: rgba(181, 12, 0, 0.6)
    .box-back
        background-color: rgba(255, 255, 0, 0.6)
    img.icon
        position: absolute
        top: 0
        left: 0
        width: $box-1x1
        height: $box-1x1
        transition: 1s
        border-radius: 50%
        border: solid #00e264
        object-fit: contain
    img.winner-img
        width: 50%
    .winner-container
        position: absolute
        top: 0
        width: 200%
        z-index: 99
        left: 0
        bottom: 0
        //background-image: url('./../../assets/winner-image-red.jpg')
        background-size: 50%
        background-repeat: no-repeat
        background-position: top 
        background-color: #F9FCFF
        border: solid white
        h3
            color: #00abcd
        button
            background-color: #00abcd
            color: white
            &:hover
                color: #00abcd
                background-color: white
                border-color: #00abcd

 </style>