<template>
    <footer>
        <div class="text-center bg-danger py-5 shadow" v-if="alertStatus">
            <h3 class="text-white my-5"> Questo è il Palio del Cyber-Eroe! </h3>
            <h5 class="text-white"> Insegui il Cyber-Criminale fino alla casella 80 per acciuffarlo e rendere il web un posto migliore. &#128540; </h5>
            <h5 class="text-white"> Presta molta attenzione alle caselle Gialle e Rosse... Al giorno d'oggi i cattivi sono veramente subdoli!</h5>
           
            <button @click="toggleAlertStatus" class="btn btn-lg btn-light text-danger my-5">
                Ho capito
            </button>
        </div>
        <h5 class="text-center text-danger py-3">Designed for <a href="https://www.sicynt.org" class="text-danger text-decoration-none">SICYNT</a> with &hearts; by Lorenzo Rottigni - lorenzo@rottigni.tech - &copy; 2021</h5>
    </footer>
</template>
<script>

export default {
    name : 'Footer',
    data(){
        return {
            alertStatus : true
        }
    },
    methods : {
            toggleAlertStatus(){
                this.alertStatus = false
            }
        }
}
</script>
<style scoped lang="sass">
    footer
        position: fixed
        bottom: 0
        left: 0
        right: 0
        background: white
        border-top: solid white
    h3
        font-size: 2.5vw
    h5
        font-size: 1.5vw
    .bg-danger
        background-color: rgb(181, 12, 0) !important
    .text-danger
        color: rgb(181, 12, 0) !important
</style>